export enum FeedbackEnum {
    POSITIVO = 'POSITIVO',
    NEGATIVO = 'NEGATIVO',
    NEUTRO = 'NEUTRO',
}

export enum TipoProceso{
    VENTA= 'VENTA',
    COTIZACION = 'COTIZACION',
    PEDIDO = 'PEDIDO',
}