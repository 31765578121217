import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { TicketPrint } from '@app/domain/ventas/ticket-print';
import { CommandPrint } from '@app/domain/restaurante/command-print';
import { CheckPrint } from '@app/domain/restaurante/check-print';
import { Observable } from 'rxjs';
import { CashRegisterPrint } from '@app/domain/cash-register-print';
import { EscPosOrder } from '@app/domain/esc-pos-order';
import { EscPosOrderTypeEnum } from '@app/enums/esc-pos-order-type.enum';
import { TicketFeePrint } from '@app/domain/restaurante/ticket-fee-print';
import { CashRegisterDetailPrint } from '@app/domain/restaurante/cash-register-detail-print';

@Injectable({
    providedIn: 'root'
})
export class CoffeEscPosService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    invoiceEscPos(id, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/invoice/${id}`).subscribe((data: TicketPrint) => {
                let separator = '------------------------------------------------';
                let headerItems = 'PRODUCTO             CANT.   P.U.      TOT.';
                if (options.printerMm === 58) {
                    separator = '-------------------------------';
                    headerItems = 'PROD.          CANT.  TOT.';
                }
                let lines: EscPosOrder[] = [];
                const productLines: EscPosOrder[] = [];
                data.items.forEach(it => {
                    it.descripcion = `${it.descripcion.toUpperCase()}`;
                    let iLineDes = 0;
                    let linesDescription: any[];
                    let widthDes;
                    if (options.printerMm === 58) {
                        widthDes = 14;
                        linesDescription = it.descripcion.match(/.{1,14}/g);
                    } else {
                        widthDes = 20;
                        linesDescription = it.descripcion.match(/.{1,20}/g);
                    }
                    linesDescription.forEach(ld => {
                        if (iLineDes === 0) {
                            let firstLine;
                            if (options.printerMm === 58) {
                                firstLine = `${ld.padEnd(widthDes)}  ${(it.cantidad).padEnd(5)}  ${it.total}`;
                            } else {
                                firstLine = `${ld.padEnd(widthDes)}  ${(it.cantidad).padEnd(6)}  ${it.precioUnitario.padEnd(8)}  ${it.total}`;
                            }
                            productLines.push(
                                {
                                    type: EscPosOrderTypeEnum.TEXT,
                                    text: {
                                        lineBreak: true,
                                        value: firstLine
                                    }
                                }
                            );
                        } else {
                            const secondLine = `${ld.padEnd(widthDes)}`;
                            productLines.push(
                                {
                                    type: EscPosOrderTypeEnum.TEXT,
                                    text: {
                                        lineBreak: true,
                                        value: secondLine
                                    }
                                }
                            );
                        }
                        iLineDes++;
                    });
                });
                const isNotaSunat = data.tipoComprobante === '07' || data.tipoComprobante === '08';
                if (data.logo && options.imprimirLogoTicket) {
                    lines.push({
                        type: EscPosOrderTypeEnum.IMAGE,
                        image: {
                            url: data.logo,
                            justification: 'Center',
                            width: 200
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.razonSocial,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.nombreComercial,
                        lineBreak: true,
                        style: {
                            justification: 'Center',
                            fontSize: '_2'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `RUC: ${data.rucEmisor}`,
                        lineBreak: true,
                        style: {
                            justification: 'Center',
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Dir.: ${data.direccionEmisor}`,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Teléf.: ${data.telefonoEmisor}   e-mail: ${data.emailEmisor}`,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.nombreComprobante,
                        lineBreak: true,
                        style: {
                            justification: 'Center',
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.serieCorrelativo,
                        lineBreak: true,
                        style: {
                            justification: 'Center',
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Fecha: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.fechaEmision,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Cliente: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.denominacionReceptor,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.tipoDocumentoReceptor,
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.numeroDocumentoReceptor,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Moneda: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.codigoMoneda,
                        lineBreak: true
                    }
                });
                if (isNotaSunat) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Comprobante afectado: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.nombreComprobanteAfectado} ${data.serieAfectado}-${data.numeroAfectado}`,
                            lineBreak: true
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Motivo nota: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.motivoNota,
                            lineBreak: true
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Dirección: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.direccionReceptor,
                        lineBreak: true
                    }
                });
                if (data.adicionales.length) {
                    data.adicionales.forEach(ad => {
                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: `${ad.nombreCampo}: `,
                                lineBreak: false,
                                style: {
                                    bold: true
                                }
                            }
                        });
                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: ad.valorCampo,
                                lineBreak: true
                            }
                        });
                    });
                }

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: headerItems,
                        lineBreak: true,
                        style: {
                            bold: true
                        }
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                lines = lines.concat(productLines);

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                if (data.tipoComprobante !== 'NV') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OP. GRAVADA: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.gravada}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                if (data.exonerada !== '0.00') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OP. EXONERADA: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.exonerada}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                if (data.gratuita !== '0.00') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OP. GRATUITA: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.gratuita}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                if (data.inafecta !== '0.00') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OP. INAFECTA: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.inafecta}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'DESCUENTO: ',
                        lineBreak: false,
                        style: {
                            bold: true,
                            justification: 'Right'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `${data.monedaSimbolo} ${data.descuento}`,
                        lineBreak: true,
                        style: {
                            justification: 'Right'
                        }
                    }
                });
                if (data.tipoComprobante !== 'NV') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'IGV: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.igv}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                if (data.isc !== '0.00') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'ISC: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.isc}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                if (data.tributosBolsas !== '0.00') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'ICBP: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.tributosBolsas}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                if (data.otrosCargos !== '0.00') {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OTROS CARGOS: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                justification: 'Right'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.monedaSimbolo} ${data.otrosCargos}`,
                            lineBreak: true,
                            style: {
                                justification: 'Right'
                            }
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `TOTAL: ${data.monedaSimbolo} ${data.totalVenta}`,
                        lineBreak: true,
                        style: {
                            bold: true,
                            justification: 'Right'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Vendedor: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.vendedor,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Tipo venta: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.tipoVenta,
                        lineBreak: true
                    }
                });
                if (data.observaciones) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Observación: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.observaciones,
                            lineBreak: true
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });
                if (data.formasPago.length) {
                    data.formasPago.forEach(fp => {
                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: `${fp.nombre}: ${fp.monto}`,
                                lineBreak: true,
                                style: {
                                    justification: 'Center',
                                    bold: true,
                                }
                            }
                        });
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Efectivo: ',
                        lineBreak: false,
                        style: {
                            bold: true,
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.efectivo,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Vuelto: ',
                        lineBreak: false,
                        style: {
                            bold: true,
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.cambio,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `SON: ${data.montoLetras}`,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                if (data.tipoComprobante !== 'NV') {
                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.QR,
                        qr: {
                            justification: 'Center',
                            size: 5,
                            value: data.qr
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `Representación impresa de la ${data.nombreComprobante}, autorizado mediante resolución N° 0340050001931 - SUNAT. Puede consultar este documento en ${data.urlConsultaComprobante}`,
                            lineBreak: true
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 5
                });
                if (options.abrirGaveta && data.efectivo && Number(data.efectivo) > 0) {
                    lines.push({
                        type: EscPosOrderTypeEnum.DRAWER
                    });
                }
                observer.next(lines);
                observer.complete();
            });
        });
    }


    commandEscPos(idCommand, params, options?: any): Observable<any[]> {

        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/command/${idCommand}?${params}`).subscribe((data: CommandPrint) => {

                let separator = '------------------------------------------------';
                if (options.paperWidth && options.paperWidth === 58) {
                    separator = '-------------------------------';
                }
                let lines: EscPosOrder[] = [];
                const productLines: EscPosOrder[] = [];

                if (!options.ocultarItems) {
                    data.items.forEach(it => {
                        it.producto = it.producto.toUpperCase();
                        it.extras = it.extras ? it.extras.toUpperCase() : it.extras;
                        const fontSize = options.letraGrandeComanda ? '_2' : '_1';
                        productLines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: `${it.cantidad} - ${it.producto}`,
                                lineBreak: true,
                                style: {
                                    bold: true,
                                    fontSize: options.fontsizeOcultarItems == undefined ? fontSize : options.fontsizeOcultarItems,
                                    fontSizeX: options.fontsizeXOcultarItems == undefined ?  '_1' : options.fontsizeXOcultarItems,
                                    fontSizeY: options.fontsizeYOcultarItems == undefined ? '_2' : options.fontsizeYOcultarItems
                                }
                            }
                        });
                        if (it.extras) {
                            productLines.push({
                                type: EscPosOrderTypeEnum.TEXT,
                                text: {
                                    value: `${it.extras}`,
                                    lineBreak: true,
                                    style: {
                                        bold: true,
                                        fontSize,
                                        fontSizeX: '_1',
                                        fontSizeY: '_2'
                                    }
                                }
                            });
                        }
                        productLines.push({
                            type: EscPosOrderTypeEnum.FEED,
                            feed: 1
                        });
                    });
                }
                if (data.logo && options.imprimirLogoTicket) {
                    lines.push({
                        type: EscPosOrderTypeEnum.IMAGE,
                        image: {
                            url: data.logo,
                            justification: 'Center',
                            width: 200
                        }
                    });
                }

                if (!options.ocultarNumeroOrden) {

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'ORDEN ' + data.orden,
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                bold: true,
                                fontSize: options.fontsizeNumeroOrden == undefined ? '_1' : options.fontsizeNumeroOrden,
                                fontSizeX: options.fontsizeXNumeroOrden == undefined ? '_1' : options.fontsizeXNumeroOrden,
                                fontSizeY: options.fontsizeYNumeroOrden == undefined ? '_1' : options.fontsizeYNumeroOrden
                            }
                        }
                    });

                }
                if (!data.anulacion && !options.ocultarFecha) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `FECHA: ${data.fecha} - ${data.hora}`,
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarFecha == undefined ? '_1' : options.fontsizeOcultarFecha,
                                fontSizeX: options.fontsizeXOcultarFecha == undefined ? '_1' : options.fontsizeXOcultarFecha,
                                fontSizeY:  options.fontsizeYOcultarFecha == undefined ?  '_2': options.fontsizeYOcultarFecha
                            }
                        }
                    });
                    // lines.push({
                    //     type: EscPosOrderTypeEnum.TEXT,
                    //     text: {
                    //         value: `${data.fecha} - ${data.hora}`,
                    //         lineBreak: true,
                    //         style: {
                    //             bold: true,
                    //             fontSizeX: '_1',
                    //             fontSizeY: '_2'
                    //         }
                    //     }
                    // });
                }
                if (data.fechaAnulacion) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `FECHA ANULACION: ${data.fechaAnulacion}`,
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSizeX: '_1',
                                fontSizeY: '_2'
                            }
                        }
                    });
                    // lines.push({
                    //     type: EscPosOrderTypeEnum.TEXT,
                    //     text: {
                    //         value: `${data.fechaAnulacion}`,
                    //         lineBreak: true,
                    //         style: {
                    //             bold: true,
                    //             fontSizeX: '_1',
                    //             fontSizeY: '_2'
                    //         }
                    //     }
                    // });
                }

                if (!options.ocultarCamarero) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'CAMARERO: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarCamarero == undefined ? '_1' : options.fontsizeOcultarCamarero,
                                fontSizeX: options.fontsizeXOcultarCamarero == undefined ?  '_1' : options.fontsizeXOcultarCamarero,
                                fontSizeY:  options.fontsizeYOcultarCamarero == undefined ?  '_2': options.fontsizeYOcultarCamarero
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.camarero?.toUpperCase(),
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarCamarero == undefined ? '_1' : options.fontsizeOcultarCamarero,
                                fontSizeX: options.fontsizeXOcultarCamarero == undefined ?  '_1' : options.fontsizeXOcultarCamarero,
                                fontSizeY:  options.fontsizeYOcultarCamarero == undefined ?  '_2': options.fontsizeYOcultarCamarero
                            }
                        }
                    });

                }

                if (!options.ocultarCliente) {

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'CLIENTE: ',
                            lineBreak: false,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarCliente == undefined ? '_1' : options.fontsizeOcultarCliente,
                                fontSizeX: options.fontsizeXOcultarCliente == undefined? '_1' : options.fontsizeXOcultarCliente,
                                fontSizeY: options.fontsizeYOcultarCliente == undefined ?'_2' : options.fontsizeYOcultarCliente
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.nombreCliente,
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarCliente == undefined ? '_1' : options.fontsizeOcultarCliente,
                                fontSizeX: options.fontsizeXOcultarCliente == undefined? '_1' : options.fontsizeXOcultarCliente,
                                fontSizeY: options.fontsizeYOcultarCliente == undefined ?'_2' : options.fontsizeYOcultarCliente
                            }
                        }
                    });

                }
                if (!options.ocultarMesa || !options.ocultarSalon) {

                    var value = ((!options.ocultarMesa) ? `MESA: ${data.mesa?.toUpperCase()}     ` : ``) + ((!options.ocultarSalon) ? `SALON: ${data.salon?.toUpperCase()}` : ``);

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: value,
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarSalon == undefined ? '_1' : options.fontsizeOcultarSalon,
                                fontSizeX: options.fontsizeXOcultarSalon == undefined ?'_1' : options.fontsizeXOcultarSalon,
                                fontSizeY: options.fontsizeYOcultarSalon == undefined? '_2' : options.fontsizeYOcultarSalon
                            }
                        }
                    });

                }

                if (!options.ocultarArea) {

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `AREA: ${data.zona?.toUpperCase()}`,
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSize: options.fontsizeOcultarArea == undefined ?'_2' : options.fontsizeOcultarArea,
                                fontSizeX: options.fontsizeXOcultarArea == undefined ? '_1' : options.fontsizeXOcultarArea,
                                fontSizeY: options.fontsizeYOcultarArea == undefined ? '_2' : options.fontsizeYOcultarArea
                            }
                        }
                    });

                }
                if (data.anulacion) {
                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `ANULACION`,
                            lineBreak: true,
                            style: {
                                bold: true,
                                fontSize: '_2',
                                justification: 'Center',
                                fontSizeX: '_1',
                                fontSizeY: '_2'
                            }
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                lines = lines.concat(productLines);

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 5
                });
                observer.next(lines);
                observer.complete();
            });
        });
    }


    checkEscPos(idCuenta, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/check-register/${idCuenta}`).subscribe((data: CheckPrint) => {
                let separator = '------------------------------------------------';
                let headerItems = 'PRODUCTO              CANT.   P.U.      TOT.';
                if (options.printerMm === 58) {
                    separator = '-------------------------------';
                    headerItems = 'PROD.          CANT.  TOT.';
                }
                let lines: EscPosOrder[] = [];
                const productLines: EscPosOrder[] = [];
                data.items.forEach(it => {

                    let iLineDes = 0;
                    let linesDescription: any[];
                    let widthDes;
                    if (options.paperWidth && options.paperWidth === 58) {
                        widthDes = 15;
                        linesDescription = it.producto.match(/.{1,14}/g);
                    } else {
                        widthDes = 21;
                        linesDescription = it.producto.match(/.{1,20}/g);
                    }
                    linesDescription.forEach(ld => {
                        if (iLineDes === 0) {
                            let firstLine;
                            if (options.paperWidth && options.paperWidth === 58) {
                                firstLine = `${ld.padEnd(widthDes)}  ${(it.cantidad).padEnd(6)}  ${it.precioVenta}`;
                            } else {
                                firstLine = `${ld.padEnd(widthDes)}  ${(it.cantidad).padEnd(6)}  ${(it.precioUnitario).padEnd(8)}  ${(it.precioVenta).padEnd(8)}`;
                            }
                            productLines.push(
                                {
                                    type: EscPosOrderTypeEnum.TEXT,
                                    text: {
                                        lineBreak: true,
                                        value: firstLine
                                    }
                                }
                            );
                        } else {
                            const secondLine = `${ld.padEnd(widthDes)}`;
                            productLines.push(
                                {
                                    type: EscPosOrderTypeEnum.TEXT,
                                    text: {
                                        lineBreak: true,
                                        value: secondLine
                                    }
                                }
                            );
                        }
                        iLineDes++;
                    });
                });
                if (data.logo && options.imprimirLogoTicket) {
                    lines.push({
                        type: EscPosOrderTypeEnum.IMAGE,
                        image: {
                            url: data.logo,
                            justification: 'Center',
                            width: 200
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.razonSocial,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.nombreComercial,
                        lineBreak: true,
                        style: {
                            justification: 'Center',
                            fontSize: '_2'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Dir.: ${data.direccion}`,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Teléf.: ${data.telefono}`,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Fecha: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.fecha,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Camarero: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.camarero,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `SALON: ${data.salon}`,
                        lineBreak: true,
                        style: {
                            bold: true,
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Mesa: ${data.mesa} - ${data.salon}`,
                        lineBreak: true,
                        style: {
                            bold: true,
                            fontSize: '_2',
                            justification: 'Center'
                        }
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: headerItems,
                        lineBreak: true,
                        style: {
                            bold: true
                        }
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                lines = lines.concat(productLines);

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `TOTAL: S/ ${data.totalPagar}`,
                        lineBreak: true,
                        style: {
                            justification: 'Right',
                            fontSize: '_2',
                            bold: true
                        }
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `SON: ${data.montoLetras}`,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                if (data.url) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.url,
                            lineBreak: true,
                            style: {
                                justification: 'Center'
                            }
                        }
                    });
                }

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'NRO. DOC.:',
                        lineBreak: true,
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true,
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'RAZON SOCIAL:',
                        lineBreak: true,
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true,
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'DIRECCION:',
                        lineBreak: true,
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true,
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 5
                });

                observer.next(lines);
                observer.complete();
            });
        });
    }

    cashRegisterEscPos(id, params, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/cash-register/${id}?${params}`)
                .subscribe((data: CashRegisterPrint) => {
                    let separator = '------------------------------------------------';
                    let headerItems = 'CONCEPTO                       MONTO            ';
                    let headerItemsVentasDetalladas = 'PROD.                        CANT.       TOTAL  ';
                    if (options.paperWidth && options.paperWidth === 58) {
                        separator = '-------------------------------';
                        headerItems = 'CONC.                 MONT.    ';
                        headerItemsVentasDetalladas = 'PROD.          CANT.   TOTAL   ';
                    }

                    let lines: EscPosOrder[] = [];
                    const linesPaymentsMethods: EscPosOrder[] = [];
                    data.montosPagosIngresos.forEach(it => {
                        let iLineDes = 0;
                        let linesDescription: any[];
                        let widthDes;
                        if (options.paperWidth && options.paperWidth === 58) {
                            widthDes = 28;
                            linesDescription = it.metodo.match(/.{1,28}/g);
                        } else {
                            widthDes = 40;
                            linesDescription = it.metodo.match(/.{1,40}/g);
                        }
                        linesDescription.forEach(ld => {
                            if (iLineDes === 0) {
                                linesPaymentsMethods.push(
                                    {
                                        type: EscPosOrderTypeEnum.TEXT,
                                        text: {
                                            value: `${ld.padEnd(widthDes)}  ${(it.monto)}`
                                        }
                                    }
                                );
                            } else {
                                linesPaymentsMethods.push(
                                    {
                                        type: EscPosOrderTypeEnum.TEXT,
                                        text: {
                                            value: `${ld.padEnd(widthDes)}`
                                        }
                                    }
                                );
                            }
                            iLineDes++;
                        });
                    });

                    const linesVentasDetalladas: EscPosOrder[] = [];
                    if (data.ventasDetalladas) {
                        data.ventasDetalladas.forEach(it => {
                            let iLineDes = 0;
                            let linesDescription: any[];
                            let widthDes;
                            if (options.paperWidth && options.paperWidth === 58) {
                                widthDes = 22;
                                linesDescription = it.descripcion.match(/.{1,22}/g);
                            } else {
                                widthDes = 40;
                                linesDescription = it.descripcion.match(/.{1,40}/g);
                            }
                            linesDescription.forEach(ld => {
                                if (iLineDes === 0) {
                                    linesVentasDetalladas.push(
                                        {
                                            type: EscPosOrderTypeEnum.TEXT,
                                            text: {
                                                value: `${ld.padEnd(widthDes)}  ${(it.cantidad)}    ${(it.total)}`
                                            }
                                        }
                                    );
                                } else {
                                    linesVentasDetalladas.push(
                                        {
                                            type: EscPosOrderTypeEnum.TEXT,
                                            text: {
                                                value: `${ld.padEnd(widthDes)}`
                                            }
                                        }
                                    );
                                }
                                iLineDes++;
                            });
                        });
                    }

                    const linesOtrosIngresos = [];
                    data.ingresos.forEach(it => {
                        let iLineDes = 0;
                        let linesDescription: any[];
                        let widthDes;
                        if (options.paperWidth && options.paperWidth === 58) {
                            widthDes = 28;
                            linesDescription = it.descripcion.match(/.{1,28}/g);
                        } else {
                            widthDes = 40;
                            linesDescription = it.descripcion.match(/.{1,40}/g);
                        }
                        linesDescription.forEach(ld => {
                            if (iLineDes === 0) {
                                linesOtrosIngresos.push(
                                    {
                                        type: EscPosOrderTypeEnum.TEXT,
                                        text: {
                                            value: `${ld.padEnd(widthDes)}  ${(it.monto)}`
                                        }
                                    }
                                );
                            } else {
                                linesOtrosIngresos.push(
                                    {
                                        type: EscPosOrderTypeEnum.TEXT,
                                        text: {
                                            value: `${ld.padEnd(widthDes)}`
                                        }
                                    }
                                );
                            }
                            iLineDes++;
                        });
                    });


                    const linesOtrosEgresos = [];
                    data.egresos.forEach(it => {
                        let iLineDes = 0;
                        let linesDescription: any[];
                        let widthDes;
                        if (options.paperWidth && options.paperWidth === 58) {
                            widthDes = 28;
                            linesDescription = it.descripcion.match(/.{1,28}/g);
                        } else {
                            widthDes = 40;
                            linesDescription = it.descripcion.match(/.{1,40}/g);
                        }
                        linesDescription.forEach(ld => {
                            if (iLineDes === 0) {
                                linesOtrosEgresos.push(
                                    {
                                        type: EscPosOrderTypeEnum.TEXT,
                                        text: {
                                            value: `${ld.padEnd(widthDes)}  ${(it.monto)}`
                                        }
                                    }
                                );
                            } else {
                                linesOtrosEgresos.push(
                                    {
                                        type: EscPosOrderTypeEnum.TEXT,
                                        text: {
                                            value: `${ld.padEnd(widthDes)}`
                                        }
                                    }
                                );
                            }
                            iLineDes++;
                        });
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 3
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'REPORTE DE CAJA',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                bold: true
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Fecha: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.fecha,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Hora de inicio: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.horaInicio,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Hora de cierre: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.horaCierre,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Turno: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.turno,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Vendedor: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.usuario,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Estado: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.estadoCaja,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Punto de venta: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.puntoVenta,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Estación: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.estacionVenta,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Moneda: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.moneda,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'BALANCE',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                fontSize: '_2'
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: headerItems,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'APERTURA                   ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoApertura}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'VENTAS EFECTIVO            ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoVentasEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OTROS INGRESOS             ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoOtrosIngresosEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OTROS EGRESOS              ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoOtrosEgresosEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'TOTAL                      ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoCajaEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'CIERRE                     ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoCierreEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'VENTAS',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                fontSize: '_2'
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: headerItems,
                            lineBreak: true,
                            style: {
                                bold: true
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'VENTAS EFECTIVO            ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoVentasEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'VENTAS TARJ., TRANS.       ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoVentasNoEfectivo}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'VENTAS CREDITO             ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoVentasCredito}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'TOTAL                      ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoTotalVentas}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'VENTAS POR DOCUMENTO',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                fontSize: '_2'
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: headerItems,
                            lineBreak: true,
                            style: {
                                bold: true
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'FACTURA                    ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoTotalVentasFactura}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'BOLETA                     ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoTotalVentasBoleta}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'NOTA DE VENTA              ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoTotalVentasNotaVenta}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'NOTA DE CREDITO            ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoTotalVentasNotaCredito}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'NOTA DE DEBITO             ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `${data.montoTotalVentasNotaDebito}`,
                            lineBreak: true,
                            style: {
                                bold: false
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'METODOS DE PAGO (INGR.)',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                fontSize: '_2'
                            }
                        }
                    });


                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: headerItems,
                            lineBreak: true,
                            style: {
                                bold: true
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines = lines.concat(linesPaymentsMethods);

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });
                    if (data.detallado) {
                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: 'VENTAS DETALLADAS',
                                lineBreak: true,
                                style: {
                                    justification: 'Center',
                                    fontSize: '_2'
                                }
                            }
                        });


                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: headerItemsVentasDetalladas,
                                lineBreak: true,
                                style: {
                                    bold: true
                                }
                            }
                        });

                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: separator,
                                lineBreak: true
                            }
                        });

                        lines = lines.concat(linesVentasDetalladas);

                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: 'TOTAL VENTAS          ',
                                lineBreak: false,
                                style: {
                                    bold: true
                                }
                            }
                        });
                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: `${data.totalVentasDetalladas}`,
                                lineBreak: true,
                                style: {
                                    bold: false
                                }
                            }
                        });

                        lines.push({
                            type: EscPosOrderTypeEnum.TEXT,
                            text: {
                                value: separator,
                                lineBreak: true
                            }
                        });
                    }

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OTROS INGRESOS',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                fontSize: '_2'
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: headerItems,
                            lineBreak: true,
                            style: {
                                bold: true
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines = lines.concat(linesOtrosIngresos);

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    //

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'OTROS EGRESOS',
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                fontSize: '_2'
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: headerItems,
                            lineBreak: true,
                            style: {
                                bold: true
                            }
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines = lines.concat(linesOtrosEgresos);

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 6
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.usuario,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 5
                    });

                    observer.next(lines);
                    observer.complete();
                });
        });
    }


    ticketFeeEscPos(idTicketFee, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/ticket-fee/${idTicketFee}`).subscribe((data: TicketFeePrint) => {
                let separator = '------------------------------------------------';
                if (options.printerMm === 58) {
                    separator = '-------------------------------';
                }
                const lines: EscPosOrder[] = [];
                if (data.logo && options.imprimirLogoTicket) {
                    lines.push({
                        type: EscPosOrderTypeEnum.IMAGE,
                        image: {
                            url: data.logo,
                            justification: 'Center',
                            width: 200
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.razonSocial,
                        lineBreak: true,
                        style: {
                            justification: 'Center'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.nombreComercial,
                        lineBreak: true,
                        style: {
                            justification: 'Center',
                            fontSize: '_2'
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Dir.: ${data.direccionEmisor}`,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Teléf.: ${data.telefonoEmisor}`,
                        lineBreak: true
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: separator,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Comprobante: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.comprobante,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Letra: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.numero,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Fecha Emisión: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.fechaEmision,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Fecha vencimiento: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.fechaVencimiento,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Importe: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.importe,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: `Aceptante: `,
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.denominacionReceptor,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Domicilio: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.direccionReceptor,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Ruc: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.numeroDocumentoReceptor,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: 'Telefono: ',
                        lineBreak: false,
                        style: {
                            bold: true
                        }
                    }
                });
                lines.push({
                    type: EscPosOrderTypeEnum.TEXT,
                    text: {
                        value: data.telefonoReceptor,
                        lineBreak: true
                    }
                });

                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 1
                });

                if (data.urlEmisor) {
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.urlEmisor,
                            lineBreak: true,
                            style: {
                                justification: 'Center'
                            }
                        }
                    });
                }
                lines.push({
                    type: EscPosOrderTypeEnum.FEED,
                    feed: 5
                });
                observer.next(lines);
                observer.complete();
            });
        });
    }

    cashRegisterDetailEscPos(idCashRegisterDetail, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/cash-register-detail/${idCashRegisterDetail}`)
                .subscribe((data: CashRegisterDetailPrint) => {
                    let separator = '------------------------------------------------';
                    if (options.printerMm === 58) {
                        separator = '-------------------------------';
                    }
                    const lines: EscPosOrder[] = [];
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `MOVIMIENTO DE CAJA ${data.tipoMovimiento}`,
                            lineBreak: true,
                            style: {
                                justification: 'Center',
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Fecha: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.fecha,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Turno: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.turno,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Surcursal: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.puntoVenta,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Caja: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.estacionVenta,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Responsable: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.usuario,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: `Descripción: `,
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.descripcion,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Detalle: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.detalle,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: 'Monto: ',
                            lineBreak: false,
                            style: {
                                bold: true
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.monto,
                            lineBreak: true
                        }
                    });

                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 1
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: separator,
                            lineBreak: true
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.TEXT,
                        text: {
                            value: data.usuario,
                            lineBreak: true,
                            style: {
                                justification: 'Center'
                            }
                        }
                    });
                    lines.push({
                        type: EscPosOrderTypeEnum.FEED,
                        feed: 5
                    });
                    observer.next(lines);
                    observer.complete();
                });
        });
    }

}
