import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {EstadoOrdenRestaurante} from '@app/enums/restaurante';
import {MotivoTraslado, TipoTransporte} from '@app/enums/guia';
import { FeedbackEnum } from '@app/enums/general';
import { EstadoPago } from '@app/enums/company-control';


@Injectable({
    providedIn: 'root'
})
export class ListasService {

    constructor() {
    }

    calendarEs = {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Borrar'
    };

    tiposPlantilla: SelectItem[] = [
        {label: 'Comprobante A4', value: 'A4'},
        {label: 'Comprobante A4 Factura', value: 'A4_FACTURA'},
        {label: 'Comprobante A4 Boleta', value: 'A4_BOLETA'},
        {label: 'Comprobante Ticket', value: 'TICKET'},
        {label: 'Cotización', value: 'COTIZACION'},
        {label: 'Guía de remisión Electrónica', value: 'GUIA'},
        {label: 'Guía de remisión Electrónica Ticket', value: 'GUIA_TICKET'},
        {label: 'Guía de remisión Manual', value: 'GUIA_MANUAL'},
        {label: 'Constancia de entrega', value: 'CONSTANCIA_ENTREGA'},
        {label: 'Retención', value: 'RETENCION'},
        {label: 'Percepción', value: 'PERCEPCION'},
        {label: 'Ticket restaurante', value: 'TICKET_RESTAURANTE'},
        {label: 'Comunicación de baja', value: 'TICKET_VOIDED'},
        {label: 'Reporte caja', value: 'REPORTE_CAJA'},
        {label: 'Reporte caja ticket', value: 'REPORTE_CAJA_TICKET'},
        {label: 'Restaurante cuenta', value: 'CUENTA_RESTAURANTE'},
        {label: 'Restaurante Orden cocina', value: 'ORDEN_RESTAURANTE'},
        {label: 'Letra', value: 'LETRA'},
        {label: 'Movimiento de caja', value: 'MOVIMIENTO_CAJA'},
        {label: 'Compra', value: 'COMPRA'},
        {label: 'Despacho', value: 'DESPACHO'},
        {label: 'Cuentas por cobrar', value: 'POR_COBRAR'}
    ];

    formatosPlantilla: SelectItem[] = [
        {label: 'jrxml', value: 'JRXML'},
        {label: 'html', value: 'HTML'}
    ];

    formasPagoMantenimiento: SelectItem[] = [
        {label: 'Pago en efectivo', value: 'EFECTIVO'},
        {label: 'Pago en transferencia', value: 'TRANSFERENCIA'},
        {label: 'Pago en tarjeta', value: 'TARJETA'}
    ];

    tipoTransferencia: SelectItem[] = [
        {label: 'Yape', value: 'yape'},
        {label: 'Plin', value: 'plin'},
        {label: 'Izipay', value: 'izipay'},
        {label: 'Culqui', value: 'culqui'},
        {label: 'Niubiz', value: 'niubiz'},
        {label: 'Transferencia bancaria', value: 'transferencia'},
        {label: 'Otros', value: 'otros'}
    ];

    tiposReportePlantilla: SelectItem[] = [
        {label: 'Caja', value: 'CAJA'},
        {label: 'Caja ticket', value: 'CAJA_TICKET'}
    ];


    monedas: SelectItem[] = [
        {label: 'Sol', value: 'PEN'},
        {label: 'Dólar', value: 'USD'},
        {label: 'Euro', value: 'EUR'},
        {label: 'Yen', value: 'JPY'}
    ];

    tiposOperacion: SelectItem[] = [
        {label: 'Venta interna [0101]', value: '0101'},
        {label: 'Exportación [0200]', value: '0200'},
        {label: 'Operación Sujeta a Detracción [1001]', value: '1001'},
        {label: 'Operación Sujeta a Percepción [2001]', value: '2001'}
    ];

    tiposDetraccion: SelectItem[] = [
        {value: '001', label: 'Azúcar'},
        {value: '002', label: 'Arroz pilado'},
        {value: '003', label: 'Alcohol etílico'},
        {value: '004', label: 'Recursos hidrobiológicos'},
        {value: '005', label: 'Maíz amarillo duro'},
        {value: '007', label: 'Caña de azúcar'},
        {value: '008', label: 'Madera'},
        {value: '009', label: 'Arena y piedra.'},
        {value: '010', label: 'Residuos'},
        {value: '011', label: 'Bienes del inciso A) del Apéndice I de la Ley del IGV'},
        {value: '012', label: 'Intermediación laboral y tercerización'},
        {value: '013', label: 'Animales vivos'},
        {value: '014', label: 'Carnes y despojos comestibles'},
        {value: '015', label: 'Abonos'},
        {value: '016', label: 'Aceite de pescado'},
        {value: '017', label: 'Harina'},
        {value: '019', label: 'Arrendamiento de bienes muebles'},
        {value: '020', label: 'Mantenimiento y reparación de bienes muebles'},
        {value: '021', label: 'Movimiento de carga'},
        {value: '022', label: 'Otros servicios empresariales'},
        {value: '023', label: 'Leche'},
        {value: '024', label: 'Comisión mercantil'},
        {value: '025', label: 'Fabricación de bienes por encargo'},
        {value: '026', label: 'Servicio de transporte de personas'},
        {value: '027', label: 'Transporte de carga'},
        {value: '028', label: 'Transporte público de pasajeros realizado por vía terrestre'},
        {value: '030', label: 'Contratos de construcción'},
        {value: '031', label: 'Oro gravado con el IGV'},
        {value: '032', label: 'Páprika y otros frutos de los géneros capsicum o pimienta'},
        {value: '034', label: 'Minerales metálicos no auríferos'},
        {value: '035', label: 'Bienes exonerados del IGV'},
        {value: '036', label: 'Oro y demás minerales metálicos exonerados del IGV'},
        {value: '037', label: 'Demás servicios gravados con el IGV'},
        {value: '039', label: 'Minerales no metálicos'},
        {value: '040', label: 'Bien inmueble gravado con IGV'},
        {value: '041', label: 'Plomo'},
        {value: '099', label: 'Ley 30737'}
    ];

    mediosPago: SelectItem[] = [
        {value: '001', label: 'DEPÓSITO EN CUENTA'},
        {value: '002', label: 'GIRO'},
        {value: '003', label: 'TRANSFERENCIA DE FONDOS'},
        {value: '004', label: 'ORDEN DE PAGO'},
        {value: '005', label: 'TARJETA DE DÉBITO'},
        {value: '006', label: 'TARJETA DE CRÉDITO EMITIDA EN EL PAÍS POR UNA EMPRESA DEL SISTEMA FINANCIERO '},
        {value: '007', label: 'CHEQUES CON LA CLÁUSULA DE NO NEGOCIABLE'},
        {value: '008', label: 'EFECTIVO'},
        {value: '009', label: 'EFECTIVO'},
        {value: '010', label: 'MEDIOS DE PAGO USADOS EN COMERCIO EXTERIOR '},
        {value: '011', label: 'DOCUMENTOS EMITIDOS POR LAS EDPYMES Y LAS COOPERATIVAS DE AHORRO Y CRÉDITO NO AUTORIZADAS A CAPTAR DEPÓSITOS DEL PÚBLICO'},
        {value: '012', label: 'TARJETA DE CRÉDITO EMITIDA EN EL PAÍS O EN EL EXTERIOR POR UNA EMPRESA NO PERTENECIENTE AL SISTEMA FINANCIERO'},
        {value: '013', label: 'TARJETAS DE CRÉDITO EMITIDAS EN EL EXTERIOR POR EMPRESAS BANCARIAS O FINANCIERAS NO DOMICILIADAS'},
        {value: '101', label: 'TRANSFERENCIAS - COMERCIO EXTERIOR'},
        {value: '102', label: 'CHEQUES BANCARIOS - COMERCIO EXTERIOR'},
        {value: '103', label: 'ORDEN DE PAGO SIMPLE - COMERCIO EXTERIOR'},
        {value: '104', label: 'ORDEN DE PAGO DOCUMENTARIO - COMERCIO EXTERIOR'},
        {value: '105', label: 'REMESA SIMPLE - COMERCIO EXTERIOR'},
        {value: '106', label: 'REMESA DOCUMENTARIA - COMERCIO EXTERIOR'},
        {value: '107', label: 'CARTA DE CRÉDITO SIMPLE - COMERCIO EXTERIOR'},
        {value: '108', label: 'CARTA DE CRÉDITO DOCUMENTARIO - COMERCIO EXTERIOR'},
        {value: '999', label: 'OTROS MEDIOS DE PAGO'}
    ];


    tiposCalculoIsc: SelectItem[] = [
        {label: 'Sistema al valor', value: '01'},
        {label: 'Aplicación del Monto Fijo', value: '02'},
        {label: 'Sistema de Precios de Venta al Público', value: '03'}
    ];

    tiposPedidoRestaurantePorDefecto: SelectItem[] = [
        {label: 'Interno', value: 'PEDIDO_LOCAL_INTERNO'},
        {label: 'Llevar', value: 'PEDIDO_LOCAL'},
        {label: 'Delivery', value: 'PEDIDO_FORANEO'}
    ];

    tiposDocumentoIdentidad: SelectItem[] = [
        {label: 'RUC', value: '6'},
        {label: 'DNI', value: '1'},
        {label: 'CARNET DE EXTRANJERIA', value: '4'},
        {label: 'DOC.TRIB.NO.DOM.SIN.RUC', value: '0'},
        {label: 'PASAPORTE', value: '7'},
        {label: 'CED. DIPLOMATICA DE IDENTIDAD', value: 'A'}
    ];

    tiposGuias: SelectItem[] = [{value: '09', label: 'GUÍA DE REMISIÓN REMITENTE'}, {value: '31', label: 'GUÍA DE REMISIÓN TRANSPORTISTA'}];


    tiposAfectacionIgv: SelectItem[] = [
        {label: 'Gravado - Operación Onerosa', value: '10'},
        {label: 'Gravado – Retiro por premio (Gratuita)', value: '11'},
        {label: 'Gravado – Retiro por donación (Gratuita)', value: '12'},
        {label: 'Gravado – Retiro (Gratuita)', value: '13'},
        {label: 'Gravado – Retiro por publicidad (Gratuita)', value: '14'},
        {label: 'Gravado – Bonificaciones (Gratuita)', value: '15'},
        {label: 'Gravado – Ret. ent. a trabaj. (Gratuita)', value: '16'},
        {label: 'Exonerado - Operación Onerosa', value: '20'},
        {label: 'Exonerado – Transferencia Gratuita (Gratuita)', value: '21'},
        {label: 'Inafecto - Operación Onerosa', value: '30'},
        {label: 'Inafecto – Retiro por Bonificación (Gratuita)', value: '31'},
        {label: 'Inafecto – Retiro (Gratuita)', value: '32'},
        {label: 'Inafecto – Retiro por Muestras Médicas (Gratuita)', value: '33'},
        {label: 'Inafecto - Retiro por Convenio Colectivo (Gratuita)', value: '34'},
        {label: 'Inafecto – Retiro por premio (Gratuita)', value: '35'},
        {label: 'Inafecto - Retiro por publicidad (Gratuita)', value: '36'},
        {label: 'Exportación', value: '40'}
    ];

    tiposNotaCredito: SelectItem[] = [
        {label: 'Anulación de la operación', value: '01'},
        {label: 'Anulación por error en el RUC', value: '02'},
        {label: 'Corrección por error en la descripción', value: '03'},
        {label: 'Descuento global', value: '04'},
        {label: 'Descuento por ítem', value: '05'},
        {label: 'Devolución total', value: '06'},
        {label: 'Devolución por ítem', value: '07'},
        {label: 'Otros Conceptos', value: '10'},
        {label: 'NC Ajustes - montos y/o fechas de pago', value: '13'}
    ];

    tiposNotaDebito: SelectItem[] = [
        {label: 'Intereses por mora', value: '01'},
        {label: 'Aumento en el valor', value: '02'},
        {label: 'Aumento en el valor', value: '03'}
    ];

    tipoComprobantesVenta: SelectItem[] = [
        {label: 'Factura', value: '01'},
        {label: 'Boleta', value: '03'},
        {label: 'Nota de Venta', value: 'NV'}
    ];

    restriccionesCertificado: SelectItem[] = [
        {label: 'Vencido', value: 'VENCIDO'},
        {label: 'Ignorar vencimiento', value: 'IGNORAR_VENCIMIENTO'}
    ];

    tipoComprobantesCompra: SelectItem[] = [
        {label: 'Factura', value: '01'},
        {label: 'Boleta', value: '03'},
        {label: 'Nota de Venta', value: 'NV'},
        {label: 'Nota de Crédito', value: '07'}
    ];

    tipoComprobantesVentaForNote: SelectItem[] = [
        {label: 'Factura', value: '01'},
        {label: 'Boleta', value: '03'}
    ];

    tiposComprobantesNotaCredito: SelectItem[] = [
        {label: 'Nota de crédito', value: '07'}
    ];

    tiposComprobantesNotaDebito: SelectItem[] = [
        {label: 'Nota de débito', value: '08'}
    ];

    catalogo07 = [{
        id: 7,
        numero: '07',
        codigo: '10',
        descripcion: 'Gravado - Operación Onerosa',
        codigoRelacionado: '1001',
        orden: 1
    }, {
        id: 8,
        numero: '07',
        codigo: '11',
        descripcion: 'Gravado – Retiro por premio',
        codigoRelacionado: '1004',
        orden: 2
    }, {
        id: 9,
        numero: '07',
        codigo: '12',
        descripcion: 'Gravado – Retiro por donación',
        codigoRelacionado: '1004',
        orden: 3
    }, {
        id: 10,
        numero: '07',
        codigo: '13',
        descripcion: 'Gravado – Retiro',
        codigoRelacionado: '1004',
        orden: 4
    }, {
        id: 11,
        numero: '07',
        codigo: '14',
        descripcion: 'Gravado – Retiro por publicidad',
        codigoRelacionado: '1004',
        orden: 5
    }, {
        id: 12,
        numero: '07',
        codigo: '15',
        descripcion: 'Gravado – Bonificaciones',
        codigoRelacionado: '1004',
        orden: 6
    }, {
        id: 13,
        numero: '07',
        codigo: '16',
        descripcion: 'Gravado – Retiro por entrega a trabajadores',
        codigoRelacionado: '1004',
        orden: 7
    }, {
        id: 15,
        numero: '07',
        codigo: '20',
        descripcion: 'Exonerado - Operación Onerosa',
        codigoRelacionado: '1003',
        orden: 9
    }, {
        id: 16,
        numero: '07',
        codigo: '21',
        descripcion: 'Exonerado – Transferencia Gratuita',
        codigoRelacionado: '1004',
        orden: 10
    }, {
        id: 17,
        numero: '07',
        codigo: '30',
        descripcion: 'Inafecto - Operación Onerosa',
        codigoRelacionado: '1002',
        orden: 11
    }, {
        id: 18,
        numero: '07',
        codigo: '31',
        descripcion: 'Inafecto – Retiro por Bonificación',
        codigoRelacionado: '1004',
        orden: 12
    }, {
        id: 19,
        numero: '07',
        codigo: '32',
        descripcion: 'Inafecto – Retiro',
        codigoRelacionado: '1004',
        orden: 13
    }, {
        id: 20,
        numero: '07',
        codigo: '33',
        descripcion: 'Inafecto – Retiro por Muestras Médicas',
        codigoRelacionado: '1004',
        orden: 14
    }, {
        id: 21,
        numero: '07',
        codigo: '34',
        descripcion: 'Inafecto - Retiro por Convenio Colectivo',
        codigoRelacionado: '1004',
        orden: 15
    }, {
        id: 22,
        numero: '07',
        codigo: '35',
        descripcion: 'Inafecto – Retiro por premio',
        codigoRelacionado: '1004',
        orden: 16
    }, {
        id: 23,
        numero: '07',
        codigo: '36',
        descripcion: 'Inafecto - Retiro por publicidad',
        codigoRelacionado: '1004',
        orden: 17
    }, {id: 24, numero: '07', codigo: '40', descripcion: 'Exportación', codigoRelacionado: '1000', orden: 18}];

    formasPagoFinales: SelectItem[] = [
        {label: 'Efectivo', value: 'EFECTIVO'},
        {label: 'Tarjeta', value: 'TARJETA'},
        {label: 'Transferencia', value: 'TRANSFERENCIA'}
    ];

    tiposTarjeta: SelectItem[] = [
        {label: 'Visa', value: 'VISA'},
        {label: 'Mastercard', value: 'MASTERCARD'},
        {label: 'American Express', value: 'AMERICAN_EXPRESS'},
        {label: 'Diners', value: 'DINERS'}
    ];

    tiposComprobantes: SelectItem[] = [
        {label: 'Seleccione', value: null},
        {label: 'Boleta', value: '03'},
        {label: 'Factura', value: '01'},
        {label: 'Nota de crédito', value: '07'},
        {label: 'Nota de débito', value: '08'}
    ];

    tiposComprobanteVenta: SelectItem[] = [
        {label: 'Boleta', value: '03'},
        {label: 'Factura', value: '01'},
        {label: 'Nota', value: 'NV'}
    ];


    tiposVenta: SelectItem[] = [
        {label: 'Contado', value: 'CONTADO'},
        {label: 'Crédito', value: 'CREDITO'}
    ];

    estadosOrdenRestaurante: SelectItem[] = [
        {label: 'Pendiente', value: EstadoOrdenRestaurante.PENDIENTE},
        {label: 'Cancelado', value: EstadoOrdenRestaurante.CANCELADO},
        {label: 'Finalizado', value: EstadoOrdenRestaurante.FINALIZADO}
    ];


    motivosTraslado: SelectItem[] = [
        {label: 'Venta', value: MotivoTraslado.VENTA},
        {label: 'Compra', value: MotivoTraslado.COMPRA},
        {label: 'Traslados entre establecimientos de la misma empresa', value: MotivoTraslado.TRASLADO_ESTABLECIMIENTOS_MISMA_EMPRESA},
        {label: 'Traslado emisor itinerante CP', value: MotivoTraslado.TRASLADO_EMISOR_ITINERANTE_CP},
        {label: 'Importación', value: MotivoTraslado.IMPORTACION},
        {label: 'Exportación', value: MotivoTraslado.EXPORTACION},
        {label: 'Traslado a zona primaria', value: MotivoTraslado.TRASLADO_ZONA_PRIMARIA},
        {label: 'Otros', value: MotivoTraslado.OTROS}
    ];

    tiposTransporte: SelectItem[] = [
        {label: 'Público', value: TipoTransporte.TRANSPORTE_PUBLICO},
        {label: 'Privado', value: TipoTransporte.TRANSPORTE_PRIVADO}
    ];

    tipoGuia: SelectItem[] = [
        {label: 'Guía Electrónica', value: '09'},
        {label: 'Guía Manual', value: 'GM'},
        {label: 'Constancia de entrega', value: 'CE'}
    ];

    tiposImpresion: SelectItem[] = [
        {label: 'A4', value: 'A4'},
        {label: 'TICKET', value: 'TICKET'}
    ];

    tiposBooleanTexto: SelectItem[] = [
        {label: 'Si', value: true},
        {label: 'No', value: false}
    ];

    porcentajesPercepcion: SelectItem[] = [
        {label: '0.5%', value: 0.005},
        {label: '1.0%', value: 0.01},
        {label: '2.0%', value: 0.02}
    ];

    porcentajesRetencion: SelectItem[] = [
        {label: '3.0%', value: 0.03}
    ];

    estadosInventarioTraslado: SelectItem[] = [
        {label: 'Pendiente', value: 'PENDIENTE'},
        {label: 'Cancelado', value: 'CANCELADO'},
        {label: 'Enviado', value: 'ENVIADO'},
        {label: 'Recepcionado', value: 'RECEPCIONADO'}
    ];

    tiposProductosForRestaurant: SelectItem[] = [
        {label: 'Artículo', value: 'ARTICULO'},
        {label: 'Paquete', value: 'PAQUETE'},
        {label: 'Plan', value: 'PLAN'},
        {label: 'Platillo', value: 'PLATILLO'},
        {label: 'Insumo', value: 'INSUMO'},
        {label: 'Combo', value: 'COMBO'}
    ];

    tiposProductos: SelectItem[] = [
        {label: 'Artículo', value: 'ARTICULO'},
        {label: 'Paquete', value: 'PAQUETE'},
        {label: 'Plan', value: 'PLAN'}
    ];

    tiposProductosProduccion: SelectItem[] = [
        {label: 'Paquete producido', value: 'PAQUETE_PRODUCIDO'},
        {label: 'Platillo producido', value: 'PLATILLO_PRODUCIDO'}
    ];

    tipoComprobantesRelacionadosGuia: SelectItem[] = [
        {label: 'Factura', value: '01'},
        {label: 'Boleta', value: '03'},
        {label: 'Liquidación de compra', value: '04'},
        {label: 'Guía de remisión remitente', value: '09'},
        {label: 'Ticket o cinta de máquina registradora', value: '12'},
        {label: 'Comprobante de Operaciones – Ley N° 29972', value: '48'},
        {label: 'Constancia de Depósito - IVAP (Ley 28211)', value: '49'},
        {label: 'Declaración Aduanera de Mercancías', value: '50'},
        {label: 'Declaración Simplificada (DS)', value: '52'},
        {label: 'Resolución de Adjudicación de bienes – SUNAT', value: '71'},
        {label: 'Resolución de Comiso de bienes – SUNAT', value: '72'},
        {label: 'Guía de Transporte Forestal o de Fauna - SERFOR', value: '73'},
        {label: 'Guía de Tránsito – SUCAMEC', value: '74'},
        {label: 'Autorización para operar como empresa de Saneamiento Ambiental – MINSA', value: '75'},
        {label: 'Autorización para manejo y recojo de residuos sólidos peligrosos y no peligrosos', value: '76'},
        {label: 'Certificado fitosanitario la movilización de plantas, productos vegetales, y otros artículos reglamentados', value: '77'},
        {label: 'Registro Único de Usuarios y Transportistas de Alcohol Etílico', value: '78'},
        {label: 'Constancia de Depósito – Detracción', value: '80'},
        {label: 'Código de autorización emitida por el SCOP', value: '81'}
    ];

    comentarioFeedBack: SelectItem[] = [
        {label: 'Neutro', value: FeedbackEnum.NEUTRO},
        {label: 'Positivo', value: FeedbackEnum.POSITIVO},
        {label: 'Negativo', value: FeedbackEnum.NEGATIVO},
    ]

    estadoPago: SelectItem[] = [
        {label: 'Pendiente', value: EstadoPago.PENDIENTE},
        {label: 'Pagado', value: EstadoPago.PAGADO},
        {label: 'Cancelado', value: EstadoPago.CANCELADO}
    ]

}


